import React, { useEffect, useState } from "react";
import Navbar from "../../Global/Navbar";
import Footer from "../../Global/Footer";
import { Button, Card, CardGroup } from "react-bootstrap";
import { FaPlus, FaSearch } from "react-icons/fa";
import FileModal from "./filemodal";
// import { BsThreeDotsVertical } from "react-icons/bs";
import { HiCloudArrowDown } from "react-icons/hi2";
import axios from "axios";
import "react-image-lightbox/style.css"; // Import the styles
import Lightbox from "react-image-lightbox";

const Drive = (drives) => {
  const [customModalIsOpen, setCustomModalIsOpen] = useState(false);
  const [api, setApi] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const openLightbox = () => {
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const openCustomModal = () => {
    setCustomModalIsOpen(true);
  };

  const closeCustomModal = () => {
    setCustomModalIsOpen(false);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredDriveData = api.filter((drive) => {
    const title = drive.title || ''; 
    return title.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const allDrives = () => {
    const userToken = localStorage.getItem("Token");
    var config = {
      method: "get",
      url: `https://peaceservices.devssh.xyz/api/user/drive`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "response");
        setApi(response?.data?.drives);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    allDrives();
  }, []);

  const downloadImage = (imageUrl, imageName) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", imageName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  return (
    <>
      <Navbar />
      {/* <MainDrive /> */}
      <div className="container-fluid" style={{background: '#020000'}}>
        <div
          className="row"
          style={{ marginTop: "80px", marginBottom: "10px" }}
        >
          <div className="col-md-1"></div>
          <div className="col-md-5 css">
            <Button
              className="btnn"
              variant="primary"
              onClick={openCustomModal}
            >
              <FaPlus /> &nbsp;Upload
            </Button>
            <FileModal
              isOpen={customModalIsOpen}
              handleClose={closeCustomModal}
              allDrives={() => allDrives()}
            />
          </div>
          <div className="col-md-5">
            <div className="input-group mt-3">
            <input
                type="text"
                placeholder="Type to search here..."
                value={searchQuery}
                onChange={handleSearch}
                className="form-control"
              />
              <div className="input-group-append">
                <span className="input-group-text bg-transparent border-0">
                  <FaSearch style={{ color: "#555" }} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card col-md-12 mx-auto cardsty px-3 py-3"
          style={{ marginBottom: "80px" }}
        >
          <CardGroup>
            <div className="row">
              {filteredDriveData.map((drives) => (
                <div className="col-md-4 mt-2" key={drives.id}>
                  <Card style={{ marginTop: "30px" }}>
                    <Card.Header>
                      {/* <div className="row"> */}
                      <div className="col-md-12" style={{fontWeight: 'bold', color: 'white'}}>{drives.title}</div>
                      <div className="col-md-1 DivStyle roundedCircle">
                        <HiCloudArrowDown
                          onClick={() =>
                            downloadImage(
                              `https://peaceservices.devssh.xyz/storage/drive/${drives.name}`,
                              drives.name
                            )
                          }
                          className="ThreeDots"
                          style={{ fontSize: "30px" }}
                        />
                      </div>
                      {/* </div> */}
                    </Card.Header>
                    <Card.Body>
                    {/* <Card.Img
                      variant="top"
                      src={`https://peaceservices.devssh.xyz/storage/drive/${drives.name}`}
                      alt=""
                      type="image"
                    /> */}
                    <div className="imgcss">
                      <img
                        className="css"
                        src={`https://peaceservices.devssh.xyz/storage/drive/${drives.name}`}
                        alt={drives.name}
                        onClick={openLightbox}
                      />
                      {isOpen && (
                        <Lightbox
                          mainSrc={`https://peaceservices.devssh.xyz/storage/drive/${drives.name}`}
                          onCloseRequest={closeLightbox}
                        />
                      )}
                    </div>
                    {/* <img className="css" src={`https://peaceservices.devssh.xyz/storage/drive/${drives.name}`} alt={drives.name}/> */}
                    {/* <Card.Footer>
                      <small className="text-muted">
                        Last updated {drives.updated_at}
                      </small>
                    </Card.Footer> */}
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </CardGroup>
        </div>
      </div>
      <Footer />
      <style>
        {`.col-md-12.css{
          text-align-last: center;
          }
          button.btnn.btn.btn-primary {
            padding: 8px;
            padding-left: 3%;
            padding-right: 3%;
            width: auto;
            font-size: 17px;
            font-weight: bold;
        }
        img.css{
          height: 240px;
          max-width: 420px
        }
        .card-header{
          display: flex;
          justify-content: flex-start;
          flex-wrap: nowrap;
        }
        span.input-group-text.bg-transparent.border-0{
          position: absolute;
          left: 92.5%;
          top: 25%;
        }
        .imgcss{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
    }
    
        @media only screen and (max-width: 768px) {
          img.css {
            height: 240px;
            max-width: 284px;
        }
        .card-header {
          max-width: 284px;
      }
      span.input-group-text.bg-transparent.border-0{
        position: absolute;
        left: 89%;
        top: 25%;
      }
        }
          `}
      </style>
    </>
  );
};

export default Drive;
