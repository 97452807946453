import React, { useState } from "react";
import Navbar from "../Global/Navbar";
import Homebanner from "./Homebanner";
import About from "./About";
import Countersection from "./Countersection";
import Services from "./Services";
import Pricesection from "./Pricesection";
import Quote from "./Quote";
import Footer from "../Global/Footer";
import Login from "./Login";

const Home = () => {
  const [showlogin, setshowlogin] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      <Navbar setshowlogin={setshowlogin} />
      <Homebanner />
      <About />
      <Countersection />
      <Services />
      <Pricesection setshowlogin={setshowlogin} />
      <Quote />
      <Footer />
      {showlogin && <Login setshowlogin={setshowlogin} showlogin={showlogin} />}
    </div>
  );
};

export default Home;
