import React from 'react'

const Countersection = () => {
  return (
    <div
  className="counter-section text-center bg-img-full pad-top-lg pad-bottom-lg"
  style={{ backgroundImage: "url(images/img04.jpg)" }}
  data-scroll-index={2}
>
  <div className="container pad-top-xs">
    <div className="row">
      <div className="co-xs-12 col-sm-3 pad-bottom-xs">
        <span className="counter main-color">246</span>
        <span className="txt text-uppercase">clients</span>
      </div>
      <div className="co-xs-12 col-sm-3 pad-bottom-xs">
        <span className="counter main-color">390</span>
        <span className="txt text-uppercase">coffee</span>
      </div>
      <div className="co-xs-12 col-sm-3 pad-bottom-xs">
        <span className="counter main-color">56</span>
        <span className="txt text-uppercase">Awards</span>
      </div>
      <div className="co-xs-12 col-sm-3 pad-bottom-xs">
        <span className="counter main-color">470</span>
        <span className="txt text-uppercase">Brand</span>
      </div>
    </div>
  </div>
</div>

  )
}

export default Countersection