import React from 'react'

const Services = () => {
  return (
    <section
  className="services-section pad-top-lg pad-bottom-sm"
  id='services'
>
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-md-6 col-md-push-6 pad-bottom-sm">
        <div className="txt-box">
          {/* main heading start here */}
          <header className="main-heading">
            <h2 className="heading text-uppercase">
              <span className="main-color">our Great</span> services
            </h2>
            <span className="divider" />
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry Lorem Ipsum has been standard. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s
            </p>
          </header>
          <ul className="list list-unstyled">
            <li>
              <i className="fa fa-caret-right main-color" aria-hidden="true" />
              Responsive Design
            </li>
            <li>
              <i className="fa fa-caret-right main-color" aria-hidden="true" />
              Great Support
            </li>
            <li>
              <i className="fa fa-caret-right main-color" aria-hidden="true" />
              Look great on any devices
            </li>
            <li>
              <i className="fa fa-caret-right main-color" aria-hidden="true" />
              Highest Speed
            </li>
            <li>
              <i className="fa fa-caret-right main-color" aria-hidden="true" />
              Fresh Design
            </li>
            <li>
              <i className="fa fa-caret-right main-color" aria-hidden="true" />
              Useful and Intuitive Interface
            </li>
          </ul>
        </div>
      </div>
      <div className="col-xs-12 col-md-6 col-md-pull-6 pad-bottom-sm">
        {/* services box start here */}
        <ul
          className="services-box list-unstyled text-center"
          style={{ backgroundImage: "url(images/img03.jpg)" }}
        >
          <li>
            <div className="over">
              <span className="icomoon icon-document main-color" />
              <span className="title text-uppercase main-color">
                Awesome Design
              </span>
              <span className="divider white center" />
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod ever since the 1500s.
              </p>
            </div>
          </li>
          <li>
            <div className="over">
              <span className="icomoon icon-mobile main-color" />
              <span className="title text-uppercase main-color">
                Responsive Layout
              </span>
              <span className="divider white center" />
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod ever since the 1500s.
              </p>
            </div>
          </li>
          <li>
            <div className="over">
              <span className="icomoon icon-layers main-color" />
              <span className="title text-uppercase main-color">
                Easy to Customize
              </span>
              <span className="divider white center" />
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod ever since the 1500s.
              </p>
            </div>
          </li>
          <li>
            <div className="over">
              <span className="icomoon icon-gears main-color" />
              <span className="title text-uppercase main-color">
                Full Support work
              </span>
              <span className="divider white center" />
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod ever since the 1500s.
              </p>
            </div>
          </li>
        </ul>
        {/* services box end here */}
      </div>
    </div>
  </div>
</section>

  )
}

export default Services