/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";

import "swiper/css";
import axiosInstance from "../Auth/axios";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Login from "./Login";
// import axiosInstance from "../Auth/axios";

const Pricesection = ({ setshowlogin, showlogin }) => {
  const navigate = useNavigate();
  // const userToken = localStorage.getItem("Token");
  const [selectedId, setSelectedId] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");
  // const [IsLogin, setIsLogin] = useState(false);
  const token = localStorage.getItem("Token");

  const handleClick = (id, duration) => {
    setSelectedId(id);
    setSelectedDuration(duration);
  };

  console.log(selectedId, selectedDuration, "<====checkingID");
  const [packages, setpackages] = useState([]);
  useEffect(() => {
    axiosInstance
      .get("packages")
      .then((res) => {
        setpackages(res.data.package);
        console.log(res.data.package[0].id, "res.data.package");
      })
      .catch((err) => {});
  }, []);

  console.log(packages.id, "packages");
  const onToken = (e, id, duration) => {
    console.log(e, id, duration);
    try {
      const formdata = new FormData();
      formdata.append("source", e.id);
      formdata.append("package_id", id);
      formdata.append("duration", duration);
      const userToken = localStorage.getItem("Token");

      var config = {
        method: "post",
        url: `https://peaceservices.devssh.xyz/api/user/subscription`,
        data: formdata,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      const response = axios(config);
      console.log(response, "kia aya response");
      navigate("/Drive");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section
        className="bg-img-full price-section pad-top-lg pad-bottom-sm"
        style={{ backgroundImage: "url(images/img02.jpg)" }}
        id="Pricing"
      >
        <div className="container">
          <header className="main-heading row">
            <div className="col-xs-12 col-sm-10 col-sm-push-1 col-lg-8 col-lg-push-2 text-center">
              <h2 className="heading text-uppercase">
                <span className="main-color">Great</span> Pricing Table
              </h2>
              <span className="divider center" />
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
          </header>
          {/* main heading end here */}
          <div className="row">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
              className="mySwiper"
            >
              {packages?.map((data, index) => (
                <SwiperSlide className="pad-bottom-sm " key={index}>
                  <div className="price-box">
                    <header className="header">
                      <strong className="title text-uppercase">
                        {data?.name}
                      </strong>
                      <span className="price-txt">
                        <span className="price main-color">${data?.price}</span>
                        <span className="txt"> / {data?.period}</span>
                      </span>
                      <p>Duration : {data?.duration}</p>
                    </header>
                    <div className="box">
                      <h3 className="text-uppercase">{data?.name}</h3>
                      <span className="divider" />
                      <p>{data?.description}</p>
                      {token ? (
                        <StripeCheckout
                          token={(e) => onToken(e, data.id, data.duration)}
                          onClick={handleClick}
                          stripeKey="pk_test_51H3KeDLvjcrAVeIdp3LI62X7K6TXJDwWooz4uKc5p6jck6NdVkWbbHX2boa9sFLfRgnNGHQaODEFKpnRJIsx5qsn00F4MmOEdu"
                        >
                          <Link className="btn btn-primary order text-uppercase lightbox">
                            ${data?.price} order now...
                          </Link>
                        </StripeCheckout>
                      ) : (
                        <button
                          className="btn btn-primary order text-uppercase lightbox"
                          onClick={() => setshowlogin(true)}
                        >
                          login first
                        </button>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
      {showlogin && <Login setshowlogin={setshowlogin} />}
    </>
  );
};

export default Pricesection;
