import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
    <footer id="footer" className="dark-bg pad-top-xs pad-bottom-xs">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            {/* footer logo */}
            <div className="logo pull-left">
              {/* <a href="home.html">
            <img
              src="images/logo.png"
              alt="swam lake"
              className="img-responsive"
            />
          </a> */}
              <Link class="navbar-brand" to="/">
                <h3 className="fw-bold scss">AT PEACE SERVICES</h3>
              </Link>
            </div>
          </div>
          <div className="col-xs-12 col-sm-8">
            {/* top list */}
            <ul className="list-inline text-right top-list">
              <li>
                <i className="fa fa-envelope main-color" />
                <strong>Contact US :</strong>
                <a href="mailto:Mail@Example.com">Mail@Example.com</a>
              </li>
              <li>
                <i className="fa fa-phone main-color" />
                <strong>Call Us :</strong>
                <a href="tel:+201093515252">+201093515252</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {/* footer bottom container */}
          <div className="col-xs-12 col-sm-6 bottom">
            <p>2023 © At Peace. All rights reserved.</p>
          </div>
          {/* footer bottom container */}
          <div className="col-xs-12 col-sm-6 bottom">
            <ul className="list-inline text-right mt-social">
              <li>
                <a href="#s">
                  <i className="fa fa-facebook" />
                </a>
              </li>
              <li>
                <a href="#s">
                  <i className="fa fa-behance" />
                </a>
              </li>
              <li>
                <a href="#s">
                  <i className="fa fa-twitter" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    </>
  );
};

export default Footer;
