import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
// import { useNavigate } from "react-router-dom";

const Login = ({ setshowlogin, setLogin }) => {
  const [loader, setLoaders] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const navigate = useNavigate();

  const AddLogin = async (e) => {
    setLoaders(true);
    e.preventDefault();
    try {
      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("password", password);
      var config = {
        method: "post",
        url: `https://peaceservices.devssh.xyz/api/user/login`,
        data: formdata,
        headers: {
          Accept: "application/json",
          // Authorization: `Bearer ${userToken}`,
        },
      };
      const response = await axios(config);
      setLoaders(false);
      console.log(response, "all response");
      if (response?.data?.status) {
        // navigate("/");
        setshowlogin(false);
        window.location.reload();
        localStorage.setItem("Token", response.data.token);
        toast.success(response.data.message);
      }

      // toast.success('Successfully Login')
    } catch (error) {
      setLoaders(false);
      console.log(error);
      toast.success(error?.message);
    }
  };
  return (
    <div className="modal-overlay">
      <section
        className="quote-form modal"
        style={{ backgroundImage: "url(images/img02.jpg)" }}
        data-scroll-index={1}
      >
        <h2 className="form-heading text-center text-uppercase">Login now</h2>
        <span className="close-button" onClick={() => setshowlogin(false)}>
          X
        </span>
        <span className="form-title text-center">Feel free to Login</span>
        <form>
          <fieldset>
            <div className="form-group">
              <input
                type="text"
                placeholder="Email"
                className="form-control"
                name="email"
                // value={data.fname}
                onChange={(e) => setEmail(e.target.value)}
                required=""
                data-error="NEW ERROR MESSAGE"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                name="Password"
                // value={data.lname}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="form-control"
                required=""
                data-error="NEW ERROR MESSAGE"
              />
            </div>

            <div id="msgSubmit" className="form-message hidden" />
            <span className="info">
              <i className="fa fa-info-circle main-color" aria-hidden="true" />{" "}
              We will never share your personal info
            </span>
            <button
              className="btn btn-default main-bg-color"
              type="submit"
              id="form-submit"
              onClick={AddLogin}
            >
              {loader ? "..." : " Login"}
            </button>
          </fieldset>
        </form>
      </section>
    </div>
  );
};

export default Login;
