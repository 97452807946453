import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home";
import Drive from "./Components/Drive/Drive";
import { RequireAuth } from "./requirdAuth";
// import MainDrive from "./Components/Drive/MainDrive";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route
          path="/Drive"
          element={
            <RequireAuth>
              <Drive />
            </RequireAuth>
          }
        />
        {/* <Route path="/Drive" index element={<Drive />} /> */}
        {/* <Route path="/MainDrive" index element={<MainDrive />}/> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
