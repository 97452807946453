import React from 'react'

const About = () => {
  return (
    <section
  className="about-section pad-top-lg pad-bottom-sm"
  id='About'
>
  <div className="container">
    {/* main heading start here */}
    <header className="main-heading row">
      <div className="col-xs-12 col-sm-10 col-sm-push-1 col-lg-8 col-lg-push-2 text-center">
        <h2 className="heading text-uppercase">
          <span className="main-color">about</span> At Peace
        </h2>
        <span className="divider center" />
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s
        </p>
      </div>
    </header>
    <div className="row">
      {/* about box start here */}
      <div className="col-xs-12 col-sm-6 col-md-3 about-box pad-bottom-sm">
        <span className="num main-color">01.</span>
        <span className="title text-uppercase">the company</span>
        <span className="divider" />
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been indust...
        </p>
        <a href="/" className="more main-color text-uppercase">
          READ MORE...
        </a>
      </div>
      {/* about box end here */}
      {/* about box start here */}
      <div className="col-xs-12 col-sm-6 col-md-3 about-box pad-bottom-sm">
        <span className="num main-color">02.</span>
        <span className="title text-uppercase">our vision</span>
        <span className="divider" />
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been indust...
        </p>
        <a href="/" className="more main-color text-uppercase">
          READ MORE...
        </a>
      </div>
      {/* about box end here */}
      {/* about box start here */}
      <div className="col-xs-12 col-sm-6 col-md-3 about-box pad-bottom-sm">
        <span className="num main-color">03.</span>
        <span className="title text-uppercase">Our mission</span>
        <span className="divider" />
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been indust...
        </p>
        <a href="/" className="more main-color text-uppercase">
          READ MORE...
        </a>
      </div>
      {/* about box end here */}
      {/* about box start here */}
      <div className="col-xs-12 col-sm-6 col-md-3 about-box pad-bottom-sm">
        <span className="num main-color">04.</span>
        <span className="title text-uppercase">our process</span>
        <span className="divider" />
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been indust...
        </p>
        <a href="/" className="more main-color text-uppercase">
          READ MORE...
        </a>
      </div>
      {/* about box end here */}
    </div>
  </div>
</section>

  )
}

export default About