import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Navbar = ({ setshowlogin }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [Showmini, setShowmini] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 150) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("Token");
    if (accessToken) {
      setIsLoggedIn(true);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    window.location.reload();
  };

  return (
    <>
      <header
        id="header"
        className={`dark-bg ${isSticky ? "sticky" : ""} ${
          Showmini ? "nav-active" : "dark-bg"
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-4">
              {/* header logo */}
              <div className="logo pull-left">
                {/* <a href="#Home"> */}
                <Link class="navbar-brand" to="/">
                  <h3 className="fw-bold scss">AT PEACE SERVICES</h3>
                </Link>
                {/* <img
                  src="images/logos.png"
                  alt="Logo"
                  className="img-responsive"
                /> */}
                {/* </a> */}
              </div>
              <span
                onClick={() => setShowmini(!Showmini)}
                className="nav-opener pull-right"
              >
                <i className="fa fa-bars" aria-hidden="true" />
              </span>
            </div>
            <div className="col-xs-12 col-sm-8">
              {/* top list */}
              <ul className="list-inline text-right top-list">
                <li>
                  <i className="fa fa-envelope main-color" />
                  <strong>Contact US :</strong>
                  <a href="mailto:Mail@Example.com">Mail@Example.com</a>
                </li>
                <li>
                  <i className="fa fa-phone main-color" />
                  <strong>Call Us :</strong>
                  <a href="tel:+201093515252">+201093515252</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* page navigation start here */}
        <nav id="nav">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <ul className="nav-list list-inline">
                  <li>
                    <Link to="/" className="smooth">
                      Home
                    </Link>
                  </li>
                  <li>
                    <a href="#About" className="smooth">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="#services" className="smooth">
                      Our Services
                    </a>
                  </li>
                  <li>
                    <a href="#Pricing" className="smooth">
                      Pricing Table
                    </a>
                  </li>
                  <li>
                    <Link to="/Drive" className="smooth">
                      Drive
                    </Link>
                  </li>
                  {/* <li>
                  <a href="mailto:Mail@Example.com">Contact Us</a>
                </li> */}
                  {/* 
                  {isLoggedIn ? (
                  <li>
                    <Link className="btn btn-primary main-bg-color text-uppercase smooth" to="#" onClick={handleLogout}>
                      <span>&#x1F464;</span> Logout
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link 
                    onClick={() => setshowlogin(true)}
                    className="btn btn-primary main-bg-color text-uppercase smooth">
                      <span>&#x1F464;</span> Login
                    </Link>
                  </li>
                )} */}
                  {isLoggedIn ? (
                    <li className="button">
                      <button
                        onClick={handleLogout}
                        className="btn btn-primary main-bg-color text-uppercase smooth"
                      >
                        Logout
                      </button>
                    </li>
                  ) : (
                    <li className="button">
                      <button
                        onClick={() => setshowlogin(true)}
                        className="btn btn-primary main-bg-color text-uppercase smooth"
                      >
                        Login
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </nav>
        {/* page navigation end here */}
      </header>
      <style>
        {`
       .scss {
        font-family: 'Arial', sans-serif;
        color: #345eff; 
        text-transform: uppercase;
        font-size: 1.5em; 
        letter-spacing: 2px; 
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); 
        padding: 3px; 
        background: linear-gradient(to right, #345eff, #06beb6); 
        -webkit-background-clip: text; 
        -webkit-text-fill-color: transparent; 
      }
       `}
      </style>
    </>
  );
};

export default Navbar;
