import React from 'react'

const Quote = () => {
  return (
    <section
    className="bg-img-full bg-img-parallax quote-section pad-top-lg pad-bottom-lg"
    style={{ backgroundImage: "url(images/img01.jpg)" }}
  >
    <span className="overlay" />
    <div className="container">
      <div className="row">
        <div className="col-cs-12 col-sm-10 col-lg-8 col-sm-push-1 col-lg-push-2 text-center">
          <span className="subtitle">Creative Unbounce Landing Page</span>
          <h2 className="main-color text-uppercase">get  Registered today</h2>
          <span className="divider white center" />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text ever
            since the 1500s
          </p>
          <a
            className="btn btn-default main-bg-color text-uppercase smooth"
            href='#Home'
          >
            Click here
          </a>
        </div>
      </div>
    </div>
  </section>
  
  )
}

export default Quote