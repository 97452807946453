import React, { useEffect, useState } from "react";
import axiosInstance from "../Auth/axios";
import toast, { Toaster } from "react-hot-toast";

const Homebanner = () => {
  const [data, setdata] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userToken = localStorage.getItem("Token");

  useEffect(() => {
    if (userToken) {
      setIsLoggedIn(true);
    }
  }, [userToken]);


  const Handlechange = (e) => {
    const { name, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0]; 
      setdata((pre) => ({
        ...pre,
        [name]: file,
      }));
    } else {
      const value = e.target.value;
      setdata((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    const Datatosend = new FormData();
    Datatosend.append("first_name", data?.fname);
    Datatosend.append("last_name", data?.lname);
    Datatosend.append("email", data?.email);
    Datatosend.append("password", data?.password);
    Datatosend.append("image", data?.image);
    axiosInstance
      .post("/user/register", Datatosend)
      .then((res) => {
        if(res){
          toast.success(res.data.message)
        }
      })
      .catch((err) => {
        toast.error(err.message)
      });
  };

  return (
    <section
      className="bg-img-full main-banner bg-img-parallax"
      style={{ backgroundImage: "url(images/img05.jpg)" }}
      id="Home"
    >
      <Toaster position="top-right" reverseOrder={false} />
      <span className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-7">
            {/* <span className="title">Creative Landing Page Template</span> */}
            <h1 className="heading text-uppercase">
              Let’s get started with{" "}
              <span className="main-color">At Peace</span>
            </h1>
            <span className="divider white" />
            <div className="btn-holder">
              <a
                href="#services"
                className="btn btn-default main-bg-color text-uppercase"
              >
                Explore More
              </a>
            </div>
            <span
              className="arrow"
              style={{ backgroundImage: "url(images/arrow.png)" }}
            />
          </div>
          {isLoggedIn ? null : (
          <div className="col-xs-12 col-sm-6 col-md-5">
            {/* quote form start here */}
            <section
              className="quote-form"
              style={{ backgroundImage: "url(images/img02.jpg)" }}
              data-scroll-index={1}
            >
              <h2 className="form-heading text-center text-uppercase">
                register now
              </h2>
              <span className="form-title text-center">
                Take your free trial
              </span>
              <form>
                <fieldset>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="First Name"
                      className="form-control"
                      name="fname"
                      value={data.fname}
                      onChange={Handlechange}
                      required=""
                      data-error="NEW ERROR MESSAGE"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="lname"
                      value={data.lname}
                      onChange={Handlechange}
                      placeholder="Last Name"
                      className="form-control"
                      required=""
                      data-error="NEW ERROR MESSAGE"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      value={data.email}
                      onChange={Handlechange}
                      placeholder="Your Email"
                      className="form-control"
                      required=""
                      data-error="NEW ERROR MESSAGE"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="password"
                      value={data.password}
                      onChange={Handlechange}
                      placeholder="password"
                      name="password"
                      className="form-control"
                      required=""
                    />
                  </div>
                  <div className="form-group ">
                    <input
                      type="file"
                      name="image"
                      defaultValue={data?.image}
                      onChange={Handlechange}
                      className="form-control"
                      required=""
                      data-error="NEW ERROR MESSAGE"
                    />
                  </div>
                  <div id="msgSubmit" className="form-message hidden" />
                  <span className="info">
                    <i
                      className="fa fa-info-circle main-color"
                      aria-hidden="true"
                    />{" "}
                    We will never share your personal info
                  </span>
                  <button
                    className="btn btn-default main-bg-color"
                    type="submit"
                    id="form-submit"
                    onClick={handleRegister}
                  >
                    Register
                  </button>
                </fieldset>
              </form>
            </section>
            {/* quote form end here */}
          </div>
           )}
        </div>
      </div>
    </section>
  );
};

export default Homebanner;
