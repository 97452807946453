import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function FileModal({ isOpen, handleClose, allDrives }) {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const navigate = useNavigate();
  const userToken = localStorage.getItem("Token");

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = async () => {
    try {
      const data = new FormData();
      data.append("file", file);
      data.append("title", title);

      const config = {
        method: "post",
        url: `https://peaceservices.devssh.xyz/api/user/drive`,
        data: data,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };

      const response = await axios(config);
      console.log(response, "api");
      toast.success(response?.data?.message);
      if (response.status === 200) {
        handleClose();
        allDrives();
        navigate("/Drive");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Modal show={isOpen} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modal-overlay">
            <section
              className="quote-form modal"
              style={{ backgroundImage: "url(images/img02.jpg)" }}
              data-scroll-index={1}
            >
              <h2 className="form-heading text-center text-uppercase">
                Upload Now
              </h2>
              <span className="close-button" onClick={handleClose}>
                X
              </span>
              <span className="form-title text-center"></span>
              <form>
                <fieldset>
                <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      placeholder="Title"
                      value={title}
                      onChange={handleTitleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control-file"
                      name="file"
                      onChange={handleFileChange}
                      required
                    />
                  </div>
                  <div id="msgSubmit" className="form-message hidden" />
                  <span className="info mt-3">
                    <i className="main-color" aria-hidden="true" />
                  </span>
                  <button
                    className="btn btn-default main-bg-color mt-5"
                    type="button"
                    id="form-submit"
                    onClick={handleSubmit}
                  >
                    Upload
                  </button>
                </fieldset>
              </form>
            </section>
          </div>
        </Modal.Body>
      </Modal>
      <style>
        {`
        .quote-form .btn {
          float: left;
          padding: 11px 28px;
          opacity: 1 !important;
          margin-top: 26px;
        }
        `}
      </style>
    </>
  );
}

export default FileModal;
